import React from "react";

import { useRouter } from "next/router";

import { Head } from "components/shared/layout/Head";
import { PageLayout } from "components/shared/layout/PageLayout";

import { useAuth } from "contexts/authContext";

import { getChatOverviewUrl, getDashboardUrl } from "utils/urls";

export default function Home() {
  const { user, isLoaded } = useAuth();
  const router = useRouter();

  if (!user) {
    if (router.isReady && isLoaded) router.push(getChatOverviewUrl());
  } else {
    router.push(getDashboardUrl());
  }

  return (
    <>
      <Head pageTitle="Home" />
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactNode) {
  return <PageLayout showRightPanel>{page}</PageLayout>;
};
